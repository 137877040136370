import React from "react";
import { FormControl, FormLabel, Input, Select } from "@chakra-ui/react";

import { COUNTRY_OPTIONS } from "./use-keyword-location-selector";

interface Props {
  location: string;
  onLocationChange: (value: string | null) => void;
  onLocationBlur?: () => void;
  country: string;
  onCountryChange: (value: string | null) => void;
  onCountryBlur?: () => void;
}

export function KeywordLocationSelector(props: Props) {
  const { location, onLocationChange, onLocationBlur, country, onCountryChange, onCountryBlur } = props;

  function handleLocation(e: React.ChangeEvent<HTMLInputElement>) {
    onLocationChange(e.target.value);
  }

  function handleCountry(e: React.ChangeEvent<HTMLSelectElement>) {
    onCountryChange(e.target.value);
  }

  return (
    <>
      <FormControl>
        <FormLabel>Country</FormLabel>
        <Select value={country} onChange={handleCountry} onBlur={onCountryBlur}>
          <option value="">Select Country</option>
          {COUNTRY_OPTIONS.map((option) => {
            return (
              <option value={option.location_name} key={option.country_iso_code}>
                {option.location_name}
              </option>
            );
          })}
        </Select>
      </FormControl>
      <FormControl>
        <FormLabel>Location</FormLabel>
        <Input value={location} onChange={handleLocation} onBlur={onLocationBlur} />
      </FormControl>
    </>
  );
}
