import { Suspense, lazy, useEffect } from "react";
import { Route, Routes as RouterRoutes, useLocation } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";

import { RouteAuthorization } from "../components/route-authorization";
import { AdminBillingRoute } from "./admin-billing";
import { AdminBillingCreateInvoiceRoute } from "./admin-billing-create-invoice";
import { AdminCategoriesRoute } from "./admin-categories";
const AdminCustomerKeywords = lazy(() => import("./admin-customer-keywords"));
import { AdminDomainDetailsRoute } from "./admin-domain-details";
import { AdminDomainsRoute } from "./admin-domains";
import { AdminImportInvoices } from "./admin-import-invoices";
import { AdminInvoices } from "./admin-invoices";
import { AdminPartnerDetailsRoute } from "./admin-partner-details";
import { AdminPartnersRoute } from "./admin-partners";
import { AdminPublishersRoute } from "./admin-publishers";
import { AdminPublisherDetailsRoute } from "./admin-publisher-details";
import { AdminPaymentBatchReviewRoute } from "./admin-payment-batch-review";
import { AdminReportsRoute } from "./admin-reports";
import { AdminReportsPublicationManagementRoute } from "./admin-reports-publication-management";
import { AdminSitePostDetailsRoute } from "./admin-site-post-details";
import { AdminSitePostsRoute } from "./admin-site-posts";
import { AdminSitesRoute } from "./admin-sites";
import { AdminTasksRoute } from "./admin-tasks";
import { AdminUsersRoute } from "./admin-users";
import { AdminVendorDetailsRoute } from "./admin-vendor-details";
import { BillingRoute } from "./billing";
import { BillingInvoiceRoute } from "./billing-invoice";
import { BillingPayRoute } from "./billing-pay";
import { ClientDetailsRoute } from "./client-details";
import { ClientsRoute } from "./clients";
import { ConfigurationRoute } from "./configuration";
import { ConfigurationAdminRoute } from "./configuration-admin";
import { ConfigurationAvailableBaseProductTypes } from "./configuration-available-base-product-types";
import { ConfigurationPartnerPackages } from "./configuration-partner-packages";
import { ConfigurationPartnerPackageDetails } from "./configuration-partner-package-details";
import { ConfigurationProductTypesRoute } from "./configuration-product-types";
import { ConfigurationUsersRoute } from "./configuration-users";
import { ConfirmEmail } from "./confirm-email";
import { ConversationDetailsRoute } from "./conversation-details";
import { LinkLocationsRoute } from "./admin-link-locations";
import { Login } from "./login";
import { MessagesRoute } from "./messages";
import { NotFoundRoute } from "./not-found";
import { OrderDetails } from "./order-details";
import { OrdersRoute } from "./orders";
import { ProfileRoute } from "./profile";
import { RequestPasswordReset } from "./request-password-reset";
import { ResetPassword } from "./reset-password";
import { LoginUserSsoRoute } from "./sso";
import { TaskRoute } from "./task";
import { TasksRoute } from "./tasks";
import { VendorDetailsRoute } from "./vendor-details";
import { VendorsRoute } from "./vendors";
import { DefaultRoute } from "./_default";
import { AdminLinkLocationDetails } from "./admin-link-location-details";
import { Loader } from "../components/loader";
import { AdminPublisherPaymentBatchReviewRoute } from "./admin-publisher-payment-batch-review";
import { useTrackUser } from "../common/use-track-user-event/use-track-user-event";
import { USER_EVENTS } from "../constants/user-events";
const PartnerCampaignInvoices = lazy(() => import("./partner-campaign-invoices"));
const AdminGuestArticleReports = lazy(() => import("./admin-guest-article-reports"));
const AdminTaskEdit = lazy(() => import("./admin-tasks-edit"));
const ToolsDomainMetrics = lazy(() => import("./tools-domain-metrics"));
const KeywordResearch = lazy(() => import("./keyword-research"));
const TopicGenerator = lazy(() => import("./topic-generator"));
const AdminGuestArticlePublishedContent = lazy(() => import("./admin-guest-article-published-content"));
const AdminPublisherPayments = lazy(() => import("./admin-publisher-payments"));
const AdminGoogleReviews = lazy(() => import("./admin-google-reviews"));
const AdminGoogleCallback = lazy(() => import("./admin-google-callback"));
const AdminBlockDomainCustomers = lazy(() => import("./admin-block-domain-customers"));

export function Routes() {
  const location = useLocation();
  const { trackUserEvent } = useTrackUser();

  useEffect(() => {
    trackUserEvent({ eventType: USER_EVENTS.PAGE_VIEWED, entityName: location.pathname });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  return (
    <Suspense fallback={<Loader />}>
      <RouterRoutes>
        <Route path="/" element={<DefaultRoute />} />
        <Route
          path="/login"
          element={
            <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID ?? ""}>
              <Login />
            </GoogleOAuthProvider>
          }
        />
        <Route path="/login/:code?" element={<LoginUserSsoRoute />} />
        <Route path="/confirm/:confirmationToken" element={<ConfirmEmail />} />
        <Route path="/request-password-reset" element={<RequestPasswordReset />} />
        <Route path="/reset-password/:id" element={<ResetPassword />} />
        <Route
          path="/profile"
          element={
            <RouteAuthorization>
              <ProfileRoute />
            </RouteAuthorization>
          }
        />
        <Route
          path="/clients"
          element={
            <RouteAuthorization>
              <ClientsRoute />
            </RouteAuthorization>
          }
        />
        <Route
          path="/clients/:id/*"
          element={
            <RouteAuthorization>
              <ClientDetailsRoute />
            </RouteAuthorization>
          }
        />
        <Route
          path="/orders"
          element={
            <RouteAuthorization>
              <OrdersRoute />
            </RouteAuthorization>
          }
        />
        <Route
          path="/orders/:id"
          element={
            <RouteAuthorization>
              <OrderDetails />
            </RouteAuthorization>
          }
        />
        <Route
          path="/vendors"
          element={
            <RouteAuthorization>
              <VendorsRoute />
            </RouteAuthorization>
          }
        />
        <Route
          path="/vendors/:id/*"
          element={
            <RouteAuthorization>
              <VendorDetailsRoute />
            </RouteAuthorization>
          }
        />
        <Route
          path="/messages"
          element={
            <RouteAuthorization>
              <MessagesRoute />
            </RouteAuthorization>
          }
        />
        <Route
          path="/messages/:conversationId"
          element={
            <RouteAuthorization>
              <ConversationDetailsRoute />
            </RouteAuthorization>
          }
        />
        <Route
          path="/configuration"
          element={
            <RouteAuthorization requiresPartnerAdmin>
              <ConfigurationRoute />
            </RouteAuthorization>
          }
        />
        <Route
          path="/configuration/:partnerId"
          element={
            <RouteAuthorization requiresPartnerAdmin>
              <ConfigurationRoute />
            </RouteAuthorization>
          }
        />
        <Route
          path="/configuration/:partnerId/available-product-types"
          element={
            <RouteAuthorization requiresAdmin>
              <ConfigurationAvailableBaseProductTypes />
            </RouteAuthorization>
          }
        />
        <Route
          path="/configuration/:partnerId/product-types"
          element={
            <RouteAuthorization requiresPartnerAdmin>
              <ConfigurationProductTypesRoute />
            </RouteAuthorization>
          }
        />
        <Route
          path="/configuration/:partnerId/packages"
          element={
            <RouteAuthorization requiresPartnerAdmin>
              <ConfigurationPartnerPackages />
            </RouteAuthorization>
          }
        />
        <Route
          path="/configuration/:partnerId/packages/:packageId"
          element={
            <RouteAuthorization requiresPartnerAdmin>
              <ConfigurationPartnerPackageDetails />
            </RouteAuthorization>
          }
        />
        <Route
          path="/configuration/:partnerId/users"
          element={
            <RouteAuthorization requiresPartnerAdmin>
              <ConfigurationUsersRoute />
            </RouteAuthorization>
          }
        />
        <Route
          path="/configuration/:partnerId/admin"
          element={
            <RouteAuthorization requiresAdmin>
              <ConfigurationAdminRoute />
            </RouteAuthorization>
          }
        />
        <Route path="/billing/:partnerId?" element={<BillingRoute />} />
        <Route
          path="/billing/:partnerId/pay"
          element={
            <RouteAuthorization requiresPartnerAdmin>
              <BillingPayRoute />
            </RouteAuthorization>
          }
        />
        <Route path="/billing/:partnerId/invoices/:partnerInvoiceId" element={<BillingInvoiceRoute />} />
        <Route
          path="/tasks"
          element={
            <RouteAuthorization>
              <TasksRoute />
            </RouteAuthorization>
          }
        />
        <Route
          path="/tasks/:id"
          element={
            <RouteAuthorization>
              <TaskRoute />
            </RouteAuthorization>
          }
        />
        <Route
          path="/invoices"
          element={
            <RouteAuthorization>
              <PartnerCampaignInvoices />
            </RouteAuthorization>
          }
        />
        <Route
          path="/admin/billing"
          element={
            <RouteAuthorization requiresAdmin>
              <AdminBillingRoute />
            </RouteAuthorization>
          }
        />
        <Route
          path="/admin/block-domains-customer"
          element={
            <RouteAuthorization requiresAdmin>
              <AdminBlockDomainCustomers />
            </RouteAuthorization>
          }
        />
        <Route
          path="/admin/invoices"
          element={
            <RouteAuthorization requiresAdmin>
              <AdminInvoices />
            </RouteAuthorization>
          }
        />
        <Route
          path="/admin/import-invoices"
          element={
            <RouteAuthorization requiresAdmin>
              <AdminImportInvoices />
            </RouteAuthorization>
          }
        />
        <Route
          path="/admin/billing/:partnerId"
          element={
            <RouteAuthorization requiresAdmin>
              <AdminBillingCreateInvoiceRoute />
            </RouteAuthorization>
          }
        />
        <Route
          path="/admin/site-posts"
          element={
            <RouteAuthorization requiresAdmin>
              <AdminSitePostsRoute />
            </RouteAuthorization>
          }
        />
        <Route
          path="/admin/site-posts/:id"
          element={
            <RouteAuthorization requiresAdmin>
              <AdminSitePostDetailsRoute />
            </RouteAuthorization>
          }
        />
        <Route
          path="/admin/partners"
          element={
            <RouteAuthorization requiresAdmin>
              <AdminPartnersRoute />
            </RouteAuthorization>
          }
        />
        <Route
          path="/admin/google-reviews"
          element={
            <RouteAuthorization requiresAdmin>
              <AdminGoogleReviews />
            </RouteAuthorization>
          }
        />
        <Route
          path="/admin/google-callback"
          element={
            <RouteAuthorization requiresAdmin>
              <AdminGoogleCallback />
            </RouteAuthorization>
          }
        />
        <Route
          path="/outreach/publishers"
          element={
            <RouteAuthorization requiresAdmin>
              <AdminPublishersRoute />
            </RouteAuthorization>
          }
        />
        <Route
          path="/outreach/publishers/:id/*"
          element={
            <RouteAuthorization requiresAdmin>
              <AdminPublisherDetailsRoute />
            </RouteAuthorization>
          }
        />
        <Route
          path="/outreach/published-content"
          element={
            <RouteAuthorization requiresAdmin>
              <AdminGuestArticlePublishedContent />
            </RouteAuthorization>
          }
        />
        <Route
          path="/admin/partners/:id/*"
          element={
            <RouteAuthorization requiresAdmin>
              <AdminPartnerDetailsRoute />
            </RouteAuthorization>
          }
        />
        <Route
          path="/admin/domains"
          element={
            <RouteAuthorization>
              <AdminDomainsRoute />
            </RouteAuthorization>
          }
        />
        <Route
          path="/admin/domains/:id"
          element={
            <RouteAuthorization requiresAdmin>
              <AdminDomainDetailsRoute />
            </RouteAuthorization>
          }
        />
        <Route
          path="/admin/categories"
          element={
            <RouteAuthorization requiresAdmin>
              <AdminCategoriesRoute />
            </RouteAuthorization>
          }
        />
        <Route
          path="/admin/sites"
          element={
            <RouteAuthorization requiresAdmin>
              <AdminSitesRoute />
            </RouteAuthorization>
          }
        />
        <Route
          path="/admin/users"
          element={
            <RouteAuthorization requiresAdmin>
              <AdminUsersRoute />
            </RouteAuthorization>
          }
        />
        <Route
          path="/admin/vendors/:id/*"
          element={
            <RouteAuthorization requiresAdmin>
              <AdminVendorDetailsRoute />
            </RouteAuthorization>
          }
        />
        <Route
          path="/admin/payment-batches/:id/review"
          element={
            <RouteAuthorization requiresAdmin>
              <AdminPaymentBatchReviewRoute />
            </RouteAuthorization>
          }
        />
        <Route
          path="/admin/publisher-payment-batches/:id/review"
          element={
            <RouteAuthorization requiresAdmin>
              <AdminPublisherPaymentBatchReviewRoute />
            </RouteAuthorization>
          }
        />
        <Route
          path="/outreach/auto-pay-publisher-payments"
          element={
            <RouteAuthorization requiresAdmin>
              <AdminPublisherPayments />
            </RouteAuthorization>
          }
        />
        <Route
          path="/admin/tasks"
          element={
            <RouteAuthorization requiresAdmin>
              <AdminTasksRoute />
            </RouteAuthorization>
          }
        />
        <Route
          path="/admin/tasks-edit"
          element={
            <RouteAuthorization requiresAdmin>
              <AdminTaskEdit />
            </RouteAuthorization>
          }
        />
        <Route
          path="/outreach/guest-article/*"
          element={
            <RouteAuthorization requiresAdmin>
              <AdminGuestArticleReports />
            </RouteAuthorization>
          }
        />
        <Route
          path="/admin/reports"
          element={
            <RouteAuthorization requiresAdmin>
              <AdminReportsRoute />
            </RouteAuthorization>
          }
        />
        <Route
          path="/admin/reports/publication-management"
          element={
            <RouteAuthorization requiresAdmin>
              <AdminReportsPublicationManagementRoute />
            </RouteAuthorization>
          }
        />
        <Route
          path="/outreach/link-locations/:id"
          element={
            <RouteAuthorization requiresAdmin>
              <AdminLinkLocationDetails />
            </RouteAuthorization>
          }
        />
        <Route
          path="/outreach/link-locations"
          element={
            <RouteAuthorization requiresAdmin>
              <LinkLocationsRoute />
            </RouteAuthorization>
          }
        />
        <Route
          path="/admin/customer-keywords"
          element={
            <RouteAuthorization requiresAdmin>
              <AdminCustomerKeywords />
            </RouteAuthorization>
          }
        />
        <Route
          path="/tools/domain-metrics"
          element={
            <RouteAuthorization>
              <ToolsDomainMetrics />
            </RouteAuthorization>
          }
        />
        <Route
          path="/tools/keyword-research"
          element={
            <RouteAuthorization>
              <KeywordResearch />
            </RouteAuthorization>
          }
        />
        <Route
          path="/tools/topic-generator"
          element={
            <RouteAuthorization>
              <TopicGenerator />
            </RouteAuthorization>
          }
        />
        <Route path="*" element={<NotFoundRoute />} />
      </RouterRoutes>
    </Suspense>
  );
}
